import React from 'react';

const openingHoursData = [
  {
    date: 'Monday 23 December',
    hours: '08:00 - 17:30',
  },
  {
    date: 'Tuesday 24 December',
    hours: 'Closed',
  },
  {
    date: 'Wednesday 25 December',
    hours: 'Closed (Bank Holiday)',
  },
  {
    date: 'Thursday 26 December',
    hours: 'Closed (Bank Holiday)',
  },
  {
    date: 'Friday 27 December',
    hours: '09:00 - 17:00 (HMCTS Court Fines and Warrants Only)',
  },
  {
    date: 'Saturday 28 December',
    hours: '09:00 - 14:00 (HMCTS Court Fines and Warrants Only)',
  },
  {
    date: 'Sunday 29 December',
    hours: 'Closed',
  },
  {
    date: 'Monday 30 December',
    hours: '09:00 - 17:00 (HMCTS Court Fines and Warrants Only)',
  },
  {
    date: 'Tuesday 31 December',
    hours: '09:00 - 17:00 (HMCTS Court Fines and Warrants Only)',
  },
  {
    date: 'Wednesday 1 January',
    hours: 'Closed (Bank Holiday)',
  },
]

const HolidayOpeningHoursTable = () => {
  return (
    <div style={tableContainerStyle}>
      <h2 style={headingStyle}>Christmas Opening Times</h2>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Hours</th>
          </tr>
        </thead>
        <tbody>
          {openingHoursData.map((item, index) => (
            <tr key={index}>
              <td style={dateCellStyle}>{item.date}</td>
              <td style={hoursCellStyle}>{item.hours}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
};

const tableContainerStyle = {
  maxWidth: '900px',
  margin: '0 auto',
  backgroundColor: 'white',
  color: 'black',
  padding: '20px',
  borderRadius: '5px',
  border: '1px solid #ccc',
};

const headingStyle = {
  textAlign: 'center',
  color: 'black',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
};

const dateCellStyle = {
  borderBottom: '1px solid #ccc',
  padding: '8px',
};

const hoursCellStyle = {
  borderBottom: '1px solid #ccc',
  padding: '8px',
};


export default HolidayOpeningHoursTable;
